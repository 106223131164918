import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Stack, styled } from '@mui/material';
import { FilterPanel } from '@shared/components/FilterPanel';
import { useDataLoader } from '@shared/hooks/useDataLoader';
import { Filter } from '@shared/protos/filter';
import { DataApi } from '@shared/utils/data';
import { BodyScrollEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { useCallback, useEffect, useRef } from 'react';

export interface BlotterGridRef<T> {
  getGridApi: () => GridApi<T> | null;
}

interface BlotterWidgetProps<T> extends AgGridReactProps<T> {
  dataLoader: DataApi<T>;
  dataTransformer?: (data: T[]) => any[];
  filters?: Filter[];
  disableSearchFilter?: boolean;
  blotterTitle?: string;
  onRefreshClicked?: () => void;
  getGridApi?: (gridApi: GridApi<T>) => void;
  externalSearchValue?: string;
  maxHeight?: number;
}

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 26px)',
    height: 'calc(100vh - 250px)',
  },
}));

export const BlotterWidget = <T,>({
  filters = [],
  dataLoader,
  disableSearchFilter = false,
  blotterTitle,
  onRefreshClicked,
  getGridApi,
  externalSearchValue,
  dataTransformer,
  maxHeight,
  ...gridProps
}: BlotterWidgetProps<T>) => {
  const gridApiRef = useRef<GridApi<T> | null>(null);

  const { urlParams, setURLSearchParam } = useURLSearchParams();
  const { data, fetchMoreData, fetchData } = useDataLoader(dataLoader);

  const onBlotterScrollEnd = useCallback(
    (event: BodyScrollEvent<T, any>) => {
      const { direction, api } = event;
      if (direction === 'vertical' && api.getLastDisplayedRowIndex() === data.length - 1) {
        fetchMoreData();
      }
    },
    [data, fetchMoreData]
  );

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    getGridApi?.(params.api);
  }, []);

  useEffect(() => {
    const { search: urlParamSearch, ...urlParamFilters } = urlParams;
    const searchValue = urlParamSearch || externalSearchValue || '';

    fetchData(urlParamFilters, searchValue);
  }, [externalSearchValue, urlParams, fetchData]);

  return (
    <Stack direction="column" flex={1} position="relative" sx={{ width: '100%', height: '100%' }}>
      {!!filters.length && (
        <FilterPanel
          title={blotterTitle}
          filters={filters}
          onSearch={
            !externalSearchValue
              ? (val: string) => {
                  setURLSearchParam('search', val);
                }
              : () => {}
          }
          disableSearch={disableSearchFilter || !!externalSearchValue}
          onRefreshClicked={onRefreshClicked}
        />
      )}
      <StyledBox className="ag-theme-balham-dark custom-grid">
        <AgGridReact<T>
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          {...gridProps}
          rowData={dataTransformer ? dataTransformer(data) : data}
          onBodyScrollEnd={onBlotterScrollEnd}
          tooltipShowDelay={0}
          onGridReady={onGridReady}
          domLayout={maxHeight ? 'normal' : 'autoHeight'}
          containerStyle={{ height: maxHeight ?? '100%', width: '100%' }}
        />
      </StyledBox>
    </Stack>
  );
};
