import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ChartWidgetPayload, DataFeedPermissions, FloatingChartPayload } from '@protos/charts';
import { streamDashboard } from '@services/context';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useApi } from '@shared/hooks/useApi';
import { ResolutionString } from '@tradingview/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFloatingChartState } from '../../../../../shared/contexts/FloatingChartContext';
import TradingViewChart from './TradingViewChart';
import chartDatafeed from './datafeed';

interface FloatingChartContainerProps {
  widgetId: string;
  chartSettings?: FloatingChartPayload;
}

export const StyledChartBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

export const FloatingChartContainer = ({ widgetId, chartSettings }: FloatingChartContainerProps) => {
  const user = useUserContext();
  const { apiClient } = useApi();
  const { editWidget } = streamDashboard;
  const { userProducts, tenors } = useUserProductsAndTenorsContext();
  const { currentFloatingChartState, setCurrentFloatingChartState } = useFloatingChartState();
  const [resolution, setResolution] = useState<ResolutionString>();
  const { chartState: streamUpdateFloatingChartState, symbol: streamUpdateSymbol } = chartSettings ?? { chartState: undefined, symbol: undefined };
  const chartContainerRef = useRef<HTMLDivElement>();

  const handleResolutionSetup = useCallback(() => {
    const incomingStreamInterval = streamUpdateFloatingChartState
      ? (JSON.parse(streamUpdateFloatingChartState).chartState.charts[0].panes[0].sources[0].state.interval as ResolutionString)
      : undefined;

    if (!currentFloatingChartState?.value && incomingStreamInterval && !resolution) setResolution(incomingStreamInterval);
  }, [currentFloatingChartState, streamUpdateFloatingChartState, resolution]);

  useEffect(() => {
    handleResolutionSetup();
  }, [handleResolutionSetup]);

  const handleChartSettingChange = useCallback(
    <T extends keyof ChartWidgetPayload>(key: T, newValue: ChartWidgetPayload[T]) => {
      setCurrentFloatingChartState({
        ...currentFloatingChartState,
        value: newValue,
        needsRefresh: false,
      });

      if (newValue) {
        editWidget(widgetId, { payload: { [key]: newValue } });
      }
    },
    [editWidget, currentFloatingChartState]
  );

  useEffect(() => {
    if (currentFloatingChartState?.value !== streamUpdateFloatingChartState || streamUpdateSymbol) {
      setCurrentFloatingChartState({
        value: streamUpdateFloatingChartState,
        needsRefresh: true,
        symbol: streamUpdateSymbol,
      });

      const value = streamUpdateFloatingChartState
        ? JSON.parse(streamUpdateFloatingChartState).chartState.charts[0].panes[0].sources[0].state.interval
        : undefined;
      setResolution(value as ResolutionString);
    }
  }, [streamUpdateFloatingChartState, streamUpdateSymbol]);

  useEffect(() => {
    if (!apiClient || (currentFloatingChartState && !currentFloatingChartState?.needsRefresh) || !user) return;

    handleResolutionSetup();

    const dataFeedPermissions: DataFeedPermissions = { cot_product_groups: user.cot_product_groups ?? [] };

    const datafeed = chartDatafeed(widgetId, apiClient, userProducts, tenors, dataFeedPermissions);
    const chart = new TradingViewChart({
      containerRef: chartContainerRef.current,
      chartState: currentFloatingChartState?.value,
      isFloatingChart: true,
      datafeed,
      permissions: dataFeedPermissions,
      chartInterval: resolution,
    });

    if (currentFloatingChartState?.symbol && !currentFloatingChartState.value) {
      chart.addSymbolToChart(currentFloatingChartState?.symbol);
      if (streamUpdateSymbol !== currentFloatingChartState?.symbol)
        editWidget(widgetId, { payload: { symbol: currentFloatingChartState?.symbol, chartState: undefined } });
    }

    chart.onAutoSaveNeeded(widgetId, newChartState => {
      handleChartSettingChange('chartState', newChartState);
    });

    chart.onIntervalChanged((newInterval: ResolutionString) => {
      setResolution(newInterval);
    });
  }, [widgetId, apiClient, currentFloatingChartState, userProducts, tenors, user, handleChartSettingChange]);

  return <StyledChartBox ref={chartContainerRef} />;
};
