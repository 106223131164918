import { WidgetType } from '@features/dashboard/enums';
import { useWidgets } from '@features/dashboard/widgets/useWidgets';
import { useDashboard } from '@hooks/useDashboard';
import { Dashboard } from '@protos/dashboard';
import { streamDashboard } from '@services/context';
import { dashboardJson } from '@services/streamDashboardService';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export interface DashboardStore {
  currentDashboard: Dashboard | undefined;
  allDashboards: Dashboard[];
  isLoading: boolean;
}

const DefaultDashboardWidgets = [WidgetType.News, WidgetType.Chart, WidgetType.Pricing, WidgetType.Settlements];
const EMPTY_STORE: DashboardStore = { currentDashboard: undefined, allDashboards: [], isLoading: false };
export const LatestDashboardContext = createContext<{ dashboardState: DashboardStore; isLoading: boolean }>({
  dashboardState: EMPTY_STORE,
  isLoading: false,
});
LatestDashboardContext.displayName = 'LatestDashboardContext';

export default function LatestDashboardContextProvider(props: any) {
  const { dashboardId } = useParams();
  const navigate = useNavigate();
  const { menuItems } = useWidgets();
  const { loading: isDashboardLoading, lastModified, currentDashboard } = useDashboard(dashboardId);
  const { setCurrentDashboard, getDashboard, dashboardList, numDashboards, addWidgets } = streamDashboard;

  const [data, setData] = useState<DashboardStore>(EMPTY_STORE);
  const isLoading = useMemo(() => isDashboardLoading || data.isLoading, [isDashboardLoading, data.isLoading]);

  useEffect(() => {
    const dashboard = getDashboard(dashboardId);
    const allDashboards = dashboardList();

    if (dashboardId && dashboard && !isDashboardLoading) {
      setCurrentDashboard(dashboard.id);
      setData(prev => ({ ...prev, currentDashboard: dashboard, isLoading: false, allDashboards }));
    } else if (allDashboards?.length) {
      if (window.location.href.includes('admin') || window.location.href.includes('manage-account')) {
        setData(prev => ({ ...prev, isLoading: false }));
      } else {
        const newDashboardId = allDashboards[0].id;
        navigate(`/dashboard/${newDashboardId}`);
      }
    }

    return () => {
      setCurrentDashboard(undefined);
      setData(prev => ({ ...prev, isLoading: true }));
    };
  }, [dashboardId, isDashboardLoading]);

  useEffect(() => {
    const dashboard = getDashboard(dashboardId);
    const allDashboards = dashboardList();

    if (dashboard) {
      setCurrentDashboard(dashboard.id);
      setData(prev => ({ ...prev, currentDashboard: dashboard, isLoading: false, allDashboards }));
    } else {
      setData(prev => ({ ...prev, isLoading: false, allDashboards }));
    }
  }, [lastModified, currentDashboard]);

  //Add default widgets to Default Dashboard if created and no widgets
  useEffect(() => {
    const dashboard = getDashboard(dashboardId);
    if (dashboard && dashboard.name === 'Default' && numDashboards() === 1 && !dashboard.json?.widgets?.length) {
      const json = dashboardJson(dashboard);
      const widgets = json.widgets || [];

      menuItems.forEach(widget => {
        if (!DefaultDashboardWidgets.includes(widget.type)) return;

        const widgetId = uuidv4();
        widgets.push({
          id: widgetId,
          type: widget.type,
          payload: widget.defaultPayload,
        });
      });

      addWidgets(dashboard, widgets);
    }
  }, [dashboardId, menuItems]);

  return <LatestDashboardContext.Provider value={{ dashboardState: data, isLoading }} {...props} />;
}

export function useLatestDashboardContext(): { dashboardState: DashboardStore; isLoading: boolean } {
  const data = useContext(LatestDashboardContext);

  return useMemo(() => data, [data]);
}
