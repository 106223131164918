import { useAdminApi } from '@hooks/useAdminApi';
import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { BlotterWidget } from '@shared/components/BotterWidget';
import theme from '@shared/themes/darkTheme';
import { ColDef } from 'ag-grid-community';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { TRADING_ADMIN_EXECUTIONS_COLUMNS, TRADING_ADMIN_EXECUTIONS_FILTERS } from '../tradingAdminProtos';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = (
  navigate: NavigateFunction,
  setURLSearchParam: (newParams: Record<string, any>) => void,
  currentUrlParams: Record<string, any>
) => {
  return {
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
    onCellClicked(event) {
      if (event.column.getColId() === 'report_id' && event.data['report_id']) {
        navigate(`/admin/trading/abn-reports/?id=${event.data['report_id']}`);
      } else if (event.column.getColId() === 'order_id') {
        setURLSearchParam({ ...currentUrlParams, order_id: event.data['order_id'] });
      }
    },
    cellStyle(params) {
      if (params.column.getColId() === 'report_id' && params.data['report_id']) {
        return { cursor: 'pointer' };
      }
      return null;
    },
  } as ColDef;
};

const getRowStyle = ({ data }) => {
  if (!data?.reconcile_timestamp) {
    return { backgroundColor: theme.palette.background.infoRow };
  }
  return undefined;
};

export const TradingExecutions = () => {
  const { apiClient } = useAdminApi();
  const navigate = useNavigate();
  const { resetURLSearchParams, urlParams } = useURLSearchParams();

  const dataLoader = apiClient?.executionsLoader();

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      dataLoader={dataLoader}
      columnDefs={TRADING_ADMIN_EXECUTIONS_COLUMNS}
      filters={TRADING_ADMIN_EXECUTIONS_FILTERS}
      getRowId={getRowId}
      defaultColDef={defaultColDef(navigate, resetURLSearchParams, urlParams)}
      getRowStyle={getRowStyle}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      maxHeight={1000}
    />
  );
};
