import { useAdminApi } from '@hooks/useAdminApi';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { KillSwitch } from '@protos/trading';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { useCallback, useState } from 'react';
import { useAsync } from 'react-use';
import { KillSwitchButton } from '../KillSwitchButton';
import { TradingOtcParamsForm } from './TradingOtcParamsForm';

export const TradingOtc = () => {
  const { apiClient } = useAdminApi();

  const [killSwitch, setKillSwitch] = useState<KillSwitch>();

  const onToggleKillSwitch = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, newCheckedValue: boolean) => {
      const newKillSwitch = await apiClient?.setKillSwitch(newCheckedValue, 'otc');
      if (!newKillSwitch) return;

      setKillSwitch(newKillSwitch);
    },
    [apiClient]
  );

  useAsync(async () => {
    const killSwitch = await apiClient?.getKillSwitch('otc');

    setKillSwitch(killSwitch);
  }, [apiClient]);

  return (
    <Stack gap={2} width="100%" p={2}>
      {killSwitch ? (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
          alignItems="end"
          sx={{ backgroundColor: theme.palette.background.darker, padding: 1, borderRadius: 1 }}
        >
          <FormControlLabel
            control={
              <KillSwitchButton
                sx={{ m: 1, right: -12 }}
                checked={killSwitch.active}
                onChange={(event, checked) => onToggleKillSwitch(event, checked)}
              />
            }
            label={`Kill Switch: ${killSwitch.active ? 'On' : 'Off'}`}
          />
          <Typography style={{ fontSize: 11, fontWeight: 800, textAlign: 'center' }}>
            Last triggered by {!killSwitch.user.full_name ? killSwitch.user.id : killSwitch.user.full_name} at {formatDatetime(killSwitch.timestamp)}
          </Typography>
        </Box>
      ) : null}
      <TradingOtcParamsForm />
    </Stack>
  );
};
