export const TradingProducts = ['brt', 'brtspr'];

export const MIN_SIZE_VALUE = 0;

export const DEFAULT_TRADE_SIZE = 1;

export const buyBlue = '#4189E8';
export const buyBlueHover = '#63A5F0';
export const sellRed = '#CD4B4B';
export const sellRedHover = '#E06666';
